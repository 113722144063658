import { toCamelCase } from '@/utils/string/string';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { IconType } from 'react-icons';

type Status = {
  label: string;
  color: string;
  icon: IconType;
};

type StatusLabelProps = {
  status: Status;
  justifyContent?: string;
  testId?: string;
  minWidth?: string;
};

const StatusLabel: FC<StatusLabelProps> = (props: StatusLabelProps) => {
  const {
    status: { label, color, icon },
    testId,
    justifyContent = 'flex-end',
  } = props;

  return (
    <Flex
      display='inline-flex'
      minW='fit-content'
      maxWidth='max-content'
      height='fit-content'
      py='0.25'
      pl='0.5'
      pr='1'
      justifyContent={justifyContent}
      alignItems='center'
      border='1px'
      borderColor={color}
      borderRadius='full'
      gap='0.5'
    >
      <Icon as={icon} fontSize='xs' color={color} />
      <Text fontSize='xs' lineHeight='shorter' data-testid={testId} color={color}>
        {toCamelCase(label)}
      </Text>
    </Flex>
  );
};

export default StatusLabel;
